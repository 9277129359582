import ImageSection from "../imageSection";
import Me from "../../img/TimeOff/me.png";
import Kids from "../../img/TimeOff/kids.png";
//import Lake from "../../img/TimeOff/lake-garda.png";
import Jessie from "../../img/TimeOff/jessie-and-i.png";
import Girl from "../../img/TimeOff/girl.png";
//import Figure  from "../../img/TimeOff/full-figure.png";
import Nuance from "../../img/TimeOff/nuance.png";
import MeAskew from "../../img/TimeOff/me-askew.png";


const Paintings = () => {
    return <article className="center projectSection"> 
        <h3>Paintings</h3>
        <p className="underline">In my free time, I love to paint and draw. I find that painting helps ground me in the humanity behind my work. I spend a lot of time thinking broadly about systems and society, and it can be too easy to become detached from the intimately human aspect of design. Humans are quirky, unpredictable, and have cares and concerns that differ widely from what a systems designer might think to design for, even with substantial research. I love to paint human faces, because it strengthens the connection that I have with the humanity that drives my work.</p>
        <h4>uncomfortably framed</h4>
        <ImageSection className="painting" text={""} sourceImg={MeAskew} altText={"painting"}/>
        <p className="center italic underline">oil on canvas</p>
        <h4>Nuance</h4>
        <ImageSection className="painting" text={""} sourceImg={Nuance} altText={"painting"}/>
        <p className="center italic underline">oil on canvas</p>
        <h4>to knit</h4>
        <ImageSection className="painting" text={""} sourceImg={Girl} altText={"painting"}/>
        <p className="center italic underline">oil on canvas</p>
        <h4>the youth</h4>
        <ImageSection className="painting" text={""} sourceImg={Kids} altText={"painting"}/>
        <p className="center italic underline">oil on canvas</p>
        <h4>late nights and early mornings</h4>
        <ImageSection className="painting" text={""} sourceImg={Me} altText={"painting"}/>
        <p className="center italic underline">charcoal on paper</p>
        <h4>sister bonding</h4>
        <ImageSection className="painting" text={""} sourceImg={Jessie} altText={"painting"}/>
        <p className="center italic underline">pastel on paper</p>
        <h4> </h4>
    </article>
}

export default Paintings;