import Section
 from "../section";
 import Cover from "../../img/climateresearch/cover-map_maine.png";
 import Cover2 from "../../img/climateresearch/portland-engagement-map.png";
 import seventy_two from "../../img/climateresearch/72.png";
 import legislation from "../../img/climateresearch/legislation.png";
 import maine from "../../img/climateresearch/maine.png";
 import one from "../../img/climateresearch/one.png";
 import two from "../../img/climateresearch/two.png";
 import three from "../../img/climateresearch/three.png";
 import four from "../../img/climateresearch/four.png";
 import poster from "../../img/climateresearch/poster.png";
 import card from "../../img/climateresearch/card.png";
 import live from "../../img/climateresearch/live.png";
 import concernPortland from "../../img/climateresearch/concern-portland.png";
 import engagementPortland from "../../img/climateresearch/engagement-portland.png";
 import concernMaine from "../../img/climateresearch/maine-concern.png";
 import fbPages from "../../img/climateresearch/fb-pages.png";
 import ClimateSite from "../../img/climateresearch/Climate-website–1.png";

const ClimateResearch = (props) => {
    const problemText = ["In Portland, the central urban areas are very concerned about climate change and report high rates of engaging politically with the issue. These are the same areas containing many businesses that engage publicly with climate change on social media.", "Outside of this urban core, however, concern for climate action does not necessarily correlate with political engagement. Additionally, a much higher percentage of people report being concerned about the impacts of climate change than report engaging politically with the topic.", "How might we spread the political engagement of the urban core to external urban areas? How does the presence of businesses that engage publicly with climate change impact broad public climate sentiment? Why are many individuals concerned about the impacts of climate change yet do not engage with the topic?"];
    const prototypeText = ["These maps will be useful to many organizations working on climate change, and their work forms the prototypes for climate solutions. Grassroots advocacy organizations that work to spread understanding of climate change and garner support for climate legislation will use these maps to better understand how constituents think and behave, and which geographic areas they might focus on. The Portland sustainability office is interested in using information like this to understand constituent concerns and bolster climate resilience across neighborhoods.", "Finally, I will use this research to guide my understanding of geographic distributions of climate sentiment and support my investigation into how idea flow and social influence impact attitudes, behaviors, and civic participation with climate change."];
    const implementText = [];
    return <article className="center projectSection">
        <h3>Attitudes and Political Engagement with Climate Change</h3>
        <p className="italic">Research seeking to map the high-resolution geographic distribution of climate attitudes and political engagement across Portland, Maine. A full explanation of this research is in my presentation to the Maine Conservation Voters.</p>
        <a href="https://www.youtube.com/watch?v=4teQyUB9LUU" rel="noreferrer" target="_blank">Watch Presentation</a>
        <div className="side-by-side">
            <img className={"maine-map"} src={Cover} alt="Website Cover" />
            <img className={"portland-map"} src={Cover2} alt="Website Cover" />
        </div>

        <Section number="1" className="center" value="the design itch" text={""} hasQuote={false}/>
        <h3>Climate change is a social issue.</h3>
        <div className="side-by-side">
            <img className={"left-img"} src={seventy_two} alt="72" />
            <h4>of Americans believe that climate change is a problem.</h4>
            <p>There are policies and adjustments that would significantly decrease carbon emissions, yet the United States fails to implement those policies.</p>
        </div>
        <div className="side-by-side_left">
            <h4>We need public will to pass climate legislation.</h4>
            <p>In order to build political will, we must expand political engagement with climate change. In order to expand political engagement, we must first understand existing attitudes and engagement towards climate change.</p>
            <img className={"left-img"} src={legislation} alt="legislation" />
        </div>
        <div className="side-by-side">
            <img className={"left-img"} src={maine} alt="Maine" />
            <h4>I explored methods of assessing attitudes and engagement at high geographic resolutions in Maine.</h4>
            <p>Research shows that geography is predictive of attitudes and behaviors, and understanding patterns of attitudes and behavior might allow us to design interventions to faciliate political engagement.</p>
        </div>
        <Section number="2a" className="center" value="research" text={""} hasQuote={false}/>
        <h3>Four data sources</h3>
        <div className="side-by-side">
            <img className={"left-img smaller"} src={one} alt="1" />
            <h4>Data from statewide surveys</h4>
            <p>This data was from a commissioned survey asking participants across the state of Maine how concerned they are about climate change as well as basic demographic information including zip code.</p>
        </div>
        <div className="side-by-side">
            <img className={"left-img smaller"} src={two} alt="2" />
            <h4>Data from climate advocacy groups</h4>
            <p>This data was on climate advocacy groups’ members that had engaged politically with climate change (calling congress, putting on events, climate education, etc)</p>
        </div>
        <div className="side-by-side">
            <img className={"left-img smaller"} src={three} alt="3" />
            <h4>Data from Meta's Content Library API</h4>
            <p>I queried data from public pages (mostly businesses) that posted about climate change in Portland, ME. This data gives a geographic distribution of businesses that engage with climate change publically.</p>
        </div>
       
        <div className="side-by-side">
            <img className={"left-img smaller"} src={four} alt="1" />
            <h4>Digital QR Code Survey</h4>
            <p>I launched a digital survey across the city of Portland by placing QR codes on both posters and small cards attached to candy bowls. The posters stated that the survey was about climate change, but the cards did not explain what the survey was for (to test for response bias). The survey asked participants about their level of concern towards climate change as well as their political engagement.</p>
        </div>
        <h3>Digital QR Code Survey</h3>
        <p>Built using C#/.NET Entity Framework backend and JavaScript/React frontend.</p>
        <p>Web application: <a className="no-border" rel="noreferrer" target="_blank" href="https://www.climatechangeopinions.org/">https://www.climatechangeopinions.org/</a></p>
        <p>GitHub Code: <a className="no-border" rel="noreferrer" target="_blank" href="https://github.com/gilbertcasey2/ICareAboutClimate_BE">https://github.com/gilbertcasey2/ICareAboutClimate_BE</a> / <a className="no-border" rel="noreferrer" target="_blank" href="https://github.com/gilbertcasey2/ICareAboutClimate_FE">https://github.com/gilbertcasey2/ICareAboutClimate_FE</a></p>
        <div className="side-by-side">
            <img className={"maine-map card"} src={card} alt="card" />
            <img className={"portland-map poster"} src={poster} alt="poster" />
            <img className={"maine-map"} src={live} alt="live" />
        </div>
        <img src={ClimateSite} alt="Climate Site" />

        <Section number="2b" className="center" value="the maps" text={""} hasQuote={false}/>
        <h3>Climate Sentiment in Portland, Maine</h3>
        <p>Census blocks with high levels of climate concern also have higher levels of political engagement. However, in census blocks with lower levels of climate concern, levels of political engagement does not necessarily correlate with the level of concern with climate change correlates with high levels of climate concern only .</p>
        <div className="side-by-side">
            <img className={"maine-map card right"} src={concernPortland} alt="Map of Climate Concern" />
            <img className={"portland-map poster"} src={engagementPortland} alt="Map of Climate Engagement" />
            <p className={"maine-map card"}>Respondents indicated whether they engaged politically with climate change. Affirmative responses were modeled at the census block group level using multilevel regression with post-stratification. Overall, individuals were more likely to be concerned about climate change than to engage politically. Block groups with the highest levels of concern were also more likely to engage politically.</p>
            <p className={"portland-map poster"}>Respondents were asked how much climate change would impact Maine over the next 10 years. The percentage of respondents indicating ‘a great deal’ were modeled at the census block group level using multilevel regression with post-stratification. Broadly, residents of Portland are concerned that climate change will impact them greatly.</p>
        </div>

        <h3>Climate Concern and Social Media Engagement</h3>
        <div className="side-by-side">
            <img className={"maine-map card"} src={concernMaine} alt="Map of Climate Concern" />
            <img className={"portland-map"} src={fbPages} alt="Map of FB Posts on Climate" />
            <p className={"portland-map poster fb no-margin"}>Data queried from Facebook on businesses that post about climate change using Meta’s new content library API and mapped the locations of those businesses. The size of the circle indicates number of posts by the business, and color indicates the level of public engagement with the posts. The location of businesses posting about climate change correlates heavily with block groups of high concern and political engagement with climate change.</p>
            <p className={"maine-map card no-margin"}>Data from a statewide survey measuring the percentage of citizens that believe climate change is having an impact on Maine now. Results were modeled at the zip code level using multilevel regression with post-stratification.</p>
        </div>

        <Section number="3" className="center" value="design focus" text={problemText} hasQuote={false}/>
        <Section number="4" className="center" value="prototype" text={prototypeText} hasQuote={false}/>

        <Section number="5" className="center" value="looking forward" text={implementText} hasQuote={false}/>
        <h3>Limitations and Future Work</h3>
        <div className="side-by-side">
            <div className="bgBox leftBox">
                <h4>Limitations</h4>
                <ol>
                    <li>
                        <div className="semibold">Survey Bias</div>
                        <p>It is possible that survey respondents for the digital QR code were unrepresentative/biased. I tried to compensate for this through collecting demographic data for my model, as well as by collecting responses through two types of materials, one that included the survey topic and one that did not.</p>
                    </li>
                    <li>
                        <div className="semibold">Data Limitations</div>
                        <p>Social media companies restrict access to their data, and the  only data released through the API is from public pages/posts. Most pages do not have public granular location data, meaning that  I could not use most individuals’ Facebook posts and pages.</p>
                    </li>
                    <li>
                        <div className="semibold">Multilevel Regression with Post-stratification (MrP) Limitations</div>
                        <p>MrP is more accurate if second-level variables that are predictive (i.e. political party) are used. Unfortunately, many of the more predictive variables are not available in census data and mostly do not exist at census block-group geographic resolution. Getting data on highly predictive variables at the census block-group level would improve the accuracy of the model. </p>
                    </li>
                </ol>
            </div>
            <div className="bgBox rightBox">
            <h4>Future Work</h4>
                <ol>
                    <li>
                        <div className="semibold">Mobility Data</div>
                        <p>Mobility data can be used to map behavior demographics, visualize where people interact, and proxy how ideas flow through an urban area. Future research overlaying mobility data with my results would allow for an analysis of how patterns of idea flow impact climate sentiment.</p>
                    </li>
                    <li>
                        <div className="semibold">Scaling Constructive Dialogue</div>
                        <p>Identifying silos in political engagement with climate change (which includes having discussions about climate solutions) would allow us to design interventions to bridge communication across climate sentiment silos.</p>
                    </li>
                    <li>
                        <div className="semibold">Designing Urban Systems</div>
                        <p>Identifying silos in political engagement with climate change (which includes having discussions about climate solutions) would allow us to design interventions to bridge communication across climate sentiment silos.</p>
                    </li>
                    <li>
                        <div className="semibold">Measuring Social Interactions and Influence</div>
                        <p>Geography is a proxy for idea flow, but large quantites of data from increasingly connected devices such as mobile phones would allow us more in-depth understanding of how social interaction and influence impacts climate sentiment.</p>
                    </li>
                </ol>
            </div>
        </div>

  </article>
}

export default ClimateResearch;