import Diamond from "./diamond";
import PageTitle from "./pageTitle";
import ProjectNav from "./projectNav";
import { Outlet } from "react-router-dom";

const Other = (props) => {
    const typeLabels = [
        "#MyAbility",
        "Beautiful UX",
        "Telig",
        "Strangled Egg",
        "Blast Guage System",
        "ISIS Twitter Network",
    ]
    const URLs = [
        "myability",
        "beautiful-ux",
        "telig",
        "strangled-egg",
        "blast-guage-system",
        "isis-twitter-network",
    ]
    return <section className="bigSection center timeOff">
    <div className="diamondContainer">
    <Diamond className="one"/>
    <Diamond className="two"/>
    <Diamond className="three"/>
    </div>
    <PageTitle value="overflow" />
    <ProjectNav itemList={typeLabels} urlList={URLs}/>
    <p className="italic timeOffExplanation">These are earlier projects, additional experiments, and art: some serious, many for fun, all for the sake of learning, growing, and exploring.</p>
    <Outlet />
</section>
}

export default Other;