import { Fragment, useState } from "react";


const ImageComponent = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleShowModal = () => {
        setIsOpen(prevState => !prevState);
    }
    return <Fragment>
            <img src={props.srcImg} alt={props.altText} className={isOpen ? "blur" : "no-class"} onClick={handleShowModal}/>
            {isOpen && (
                <div className="imageModal" onClick={handleShowModal}>
                    <h3 className="closeModal">x</h3>
                    <img src={props.srcImg} alt={props.altText} />
                </div>
            )}
        </Fragment>
}

export default ImageComponent;