import Header from './header';
import LinkButton from './linkButton'

const Top = (props) => {
    return <section className="top">
        <Header />
        {/* <h3 className='little i_am'>I am a</h3> */}
        <div className='top-diagonal'>
            <h1 className='titleone'>designer</h1>
            <h1 className='titletwo'>creator</h1>
            <h1 className='titlethree'>builder</h1>
        </div>
        <div className='socialchange'>
            <div>fixated on <span className='semibold'>designing the systems</span></div>
            <div className='socialchange_2'>within which we live</div> 
            <div className='socialchange_3'>for the <span className='social'>betterment of society</span></div>
        </div>
            
        
        <div className='bottomButtons'>
        <LinkButton linkTo="/mystory" buttonNumber={0} value='my story'></LinkButton>
        <LinkButton linkTo="/projects" buttonNumber={0} value='projects'></LinkButton>
        <LinkButton linkTo="/philosophy" buttonNumber={1} value='philosophy'></LinkButton>
        <LinkButton className="other" linkTo="/other" buttonNumber={2} value='other . . .'></LinkButton>
        </div>
        </section>
}

export default Top;