import Section
 from "../section";
 import ResearchAbility from "../../img/myability/research.png"
 import PostIt from "../../img/myability/post-its.JPG"
 import Prototype from "../../img/myability/prototype.png"
 import Implement from "../../img/myability/implement.png"




const MyAbility = (props) => {
    const itchText = ["Only 19% of people with disabilities are employed, compared with 63.7% of people without a reported disability. People with disabilities face many challenges that hinder their ability to gain and maintain employment.",
    "I engaged this design challenge alongside a friend of mine as part of a course in Human-Centered Design developed by IDEO.org."
    ]
    const researchText = [
        "We focused heavily on research for this project: in fact, we spent the majority of our time in the research phase. We wanted to make sure we had a robust understanding, so we used four different research approaches:",
        "1. Learn from People: We conducted many user interviews and focus groups, meeting and talking with many, many people identifiying with having a disability. We sat and listened to their challenges, their concerns, their goals. We heard their stories. They painted a picture of their lives, their struggles. Afterwards, we applied qualitative analysis to develop major themes. We coded our notes and wrote down our main take-aways on post-it notes, which we clustered together into themes. From these themes, we were able to understand the main challenges with employment that many people faced.",
        "2. Learn from Experts: We needed the perspective of people that were experts in the issues faced by the disability community. We started with secondary research using the internet. This gave us a basic understanding and also a starting point for connecting to individuals working in the disability community. Afterwords, we conducted interviews and focus groups with people who had spent years providing services for individuals with disabilities, who trained the public in these services, and people who were experts in advocating for rights for individuals with disabilities. Just as we had with the 'Learn from People' methodology, we applied qualitative analysis to identify major themes in the data we gathered.",
        "3. Immerse Yourself in Context: It was important to directly observe the challenges and struggles with finding employment firsthand. For this, we needed contextual immersion. We conducted field studies at career fairs designed to help people with disabilities find employment, and we spoke with individuals with disabilities at work about the challenges they faced. We wrote down all of our observations on sticky notes, and clustered those into themes as well.",
        "4. Seek Analagous Inspiration: We were curious about similar areas of difficulties that might have solutions that could provide inpiration. We conducted field studies with volunteer organizations providing a variety of services to better understand the art of matching people with jobs that fit them well. We analyzed this information to understand which themes could contribute to later brainstorming."
    ];
    const problemText = [
        "Based off of our research, we identified three main insights that prevented individuals with disabilities from obtaining employment. We developed a \"How might we\" statement for each of them to inform our brainstorming.",
        "1. Stigma: People see a disabled person and automatically assume that they are disabled in areas outside of their disability. How might we stop people from making assumptions about a person’s ability based on their disability?",
        "2. Government: There is little to no representation of disabled people in the government. How might we ensure that the disabled community has a voice in government?",
        "3. Transportation: Access to transportation limits where people can find work. How might we create ways for people to work if/when they can’t use public transportation?"
    ];
    const prototypeText = [
        "After some extensive brainstorming on each of the insights, we decided to focus on the first: stigma. When we clustered together all of the comments collected during our research phase that traced back to stigma, it appeared to be the most prevailing issue.",
        "We then moved into ideation. This was a highly iterative process of coming up with many ideas (some outlandish), narrowing them down, and then brainstorming off those ideas. We also looped back with individuals that we had already talked with, brainstormed more ideas and looped back again. We ultimately realized that there needed to be more public exposure to the ABILITIES of individuals with disabilitites, rather than focusing on the disability.",
        "With this, we developed prototypes for a campaign that would help and encourage individuals with disabilities to share their abilities to expose the public to the many capabilities of individuals with disabilities and reduce stigma."
    ];
    const implementText = [
        "Unfortunately, we ran out of time and never implemented our solution. We did develop high fidelity prototypes and a plan for the campaign, however!",
    ];
    const assessText = [
        "Were we to implement the campaign, we would have had a couple of key questions to assess:",
        "1. Would enough individuals with disabilitites be willing to share their story to make a difference?",
        "2. If some technology was required to submit stories, would that exclude some individuals from being able to share? How would we get around that?",
        "3. Would the campaign achieve the emphasis we hoped it would? Would it reduce stigma enough to actually make it easier for individuals with disabilities to get jobs?"
    ];
    return <article className="center projectSection">
        <h3>#MyAbility Campaign</h3>
        <p className="italic">A public campaign that reduces stigma against people with disabilities by promoting their abilities.</p>
        <img src={Implement} alt="Prototype" />
        <Section number="1" className="center" value="the design itch" text={itchText} hasQuote={false}/>
        <Section number="2" className="center" value="research" text={researchText} hasQuote={false}/>
        <img src={ResearchAbility} alt="Research Summary" />
        <Section number="3" className="center" value="design focus" text={problemText} hasQuote={false}/>
        <Section number="4" className="center" value="prototype" text={prototypeText} hasQuote={false}/>
        <img src={PostIt} alt="Ideation" />
        <img src={Prototype} alt="Prototype" />
        <Section number="5" className="center" value="implement" text={implementText} hasQuote={false}/>
        <img src={Implement} alt="Prototype" />
        <Section number="6" className="center" value="assess impact" text={assessText} hasQuote={false}/>
  </article>
}

export default MyAbility;