import PageTitle from "./pageTitle";
import Diamond from "./diamond";
import Selfie from "../img/me-professional-small.png"

const MyStory = (props) => {

    return <section className="myStory bigSection center">
        <div className="diamondContainer">
        <Diamond className="one"/>
        <Diamond className="two"/>
        <Diamond className="three"/>
        </div>
        <PageTitle value="my story" />
        <div className="selfiecontainer"><img className="selfie" src={Selfie} alt="Website Cover" /></div>
        <div className="myStoryContent">
        <h3>I seek to design systems that enable humanity to create a brighter and more sustainable future for all.</h3>
        <p>I believe that the design of society’s systems are critical in determining our collective fate, and that we can apply computation to model and design social systems to build the future we collectively want.</p>
        <p></p>
        <h4>the beginning</h4>
        <p>My journey was set in motion through an unusual sequence of events including a failing Americorps program, an ethically dubious door-to-door sales job, and a turbulent initiation into the US Army. These experiences brought me to marvel at the way our social systems shape, support, and fail us, and to fixate on how we might design them to be better.</p>
        <p></p>
        <h4>the journey</h4>
        <p>After these events, I returned to college to study design at Rochester Institute of Technology (RIT) through a Reserve Officer Training Corps (ROTC) scholarship. RIT introduced me to computer programming, and I realized it was the tool I needed for my exploration of systems. I reasoned that we might use computers to model social systems’ dynamics and use those models to prototype shifts to the system to address deficiencies. This mapped nicely onto the design process and I began experimenting with design to address social challenges such as <a  href="/projects/smiles" target="_blank" className="storyLink">mental health</a>, <a  href="/other/isis-twitter-network" target="_blank" className="storyLink">extremism</a>, and <a  href="/other/strangled-egg#strangeledEgg" target="_blank" className="storyLink">sustainability</a>. After college, I worked on projects that addressed <a  href="/other/blast-guage-system" target="_blank" className="storyLink">military safety</a>, <a  href="/projects/ahfc" target="_blank" className="storyLink">systemic oppression</a>, and <a  href="/other/myability" target="_blank" className="storyLink">challenges with employment</a> for folks with disabilities. I also worked  for Epic Systems, a healthcare IT company, where I focused on executing design research as well as building tools and implementing company processes for research in order to improve healthcare systems and make them safer for patients and providers alike.</p> 
        <p></p>
        <h4>climate change</h4>
        <p>I became increasingly concerned about the impacts of climate change, especially on poverty, stability, and national security. At Epic, co-workers and I launched an initiative at Epic that worked with customers to promote and inspire sustainable healthcare practices. I also began <a  href="projects/ccl" target="_blank" className="storyLink" rel="noreferrer">advocating for climate legislation</a> with Citizens' Climate Lobby. However, this work revealed that generating dialogue and democratic participation around climate change is a formidable challenge, and I realized that our systems of social interaction prevent us from coalescing around climate solutions.</p>
        <p></p>
        <h4>bootstrap research</h4>
        <p>I began to seriously think about designing social systems to address polarization and lack of action towards climate solutions. Around this time, I discovered Sandy Pentland’s work on human dynamics, social influence, and idea flow, and I began extensive study of social influence, systems design, and the climate crisis, eventually coming to believe that the key to confronting the social challenge behind the climate crisis is redesigning the systems that shape how we interact with each other. After a few years I joined the Berkman Klein Center (BKC) at Harvard to conduct <a  href="/projects/climateresearch" target="_blank" className="storyLink" rel="noreferrer">independent research</a> on how social interactions impact attitudes and political engagement with climate change (which I term climate sentiment).</p>
        <p></p>
        <h4>looking forward</h4>
        <p>I am now preparing to return to graduate school to formally study the impact of social influence on conversations, behavior, and democratic participation, especially with climate change. I plan to explore computational methods to measure and model systems of social influence in order to experiment with designing interventions that facilitate civic dialogue and engagement. 
        </p>
        </div>
    </section>
}

export default MyStory;