import Section
 from "../section";
 import HomePage from "../../img/CCL/Homepage.png"
 import SiteMap from "../../img/CCL/Site-Map.png"
 import UserGuide from "../../img/CCL/user-guide.png"
 import CCLCover from "../../img/CCL/cover.png"
 import CoverMain from "../../img/CCL/cover-main.png"
 import Poster from "../../img/CCL/poster.png"  
 import Banner from "../../img/CCL/banner.png" 
 import ImageSection from "../imageSection";

const CCL = (props) => {
    const itchText = ["Citizens' Climate Lobby is an orgnaization dedicated to combatting climate change by focusing on grassroots outreach to citizens and local leaders to build support for government policies that address climate change. CCL’s primary goal is to bring people together around a shared climate understanding and support for legislated solutions.",
    "The Wisconsin chapter of CCL, which I was a member of prior to moving to Maine, had decentralized information, an out-of-date-website, and difficulty onboarding new members and directing them to up-to-date information."
    ]
    const researchText = [
        "This research was largely qualitative. We spoke with new CCL members to understand their needs and questions. We also worked with CCL leaders to understand gaps in information, and to map out goals for the website. A key area was identifying what Wisconsin-specific information was needed on the state website and what could be accessed on the CCL national site. We also analyzed the existing website to see what features it contained, and what features were used.",
        "We identified that the goals of the site were to serve as a landing space for new CCL members to become aquainted with CCL broadly and learn about Wisonsin-specific goals and initiatives. New members also needed to peruse the local chapters and easily get involved.",
    ];
    const problemText = [
        "New CCL members in Wisconsin had no way to quickly learn about CCL or easily access Wisconsin-specific information, initiatives, and goals. The barrior to entry into Wisconsin CCL was too high due to difficulty accessing information.",
    ];
    const prototypeText = [
        "Based off of the identified goals, I developed a site map and mocked up some potential designs for the site.",
        "Originally, we sought to connect a state-wide site with local chapters having their own sites. However, feedback indicated that maintaining sites would be challenging for local chapters, and it made more sense to keep information upsteam in the state site."

    ];
    const implementText = [
        "There are challenges with an all-volunteer organization in maintaining the website. Because of this, I used WordPress, which has a very user-friendly admin page built in, so that any volunteer could quickly learn how to maintain it.",
        "Due to the nature of the design, I had to implement a custom theme, which I wrote in PHP and adapted to pull in 'page' and 'post' content from the WordPress database. I also wrote easy-to-use plugins to allow administrators to update content using the consistent WordPress UI. These plugins allowed for easy updates to pages that would need to be updated often, such as chapter lists and endorsements, and were written in PHP. Each plugin created its own admin section, where users could update information using a GUI consistent with the rest of WordPress. The plugin stored the data in an excel spreadsheet (so it could be manually written over or updated if needed), and I used a shortcode embedded in the user-facing UI to display the data that the plugin read from the spreadsheet.",
        "I also wrote and distributed a user guide, so that anyone can get up and running quickly with little to no training. These were all lessons learned from my AHFC project, in which the AHFC community had difficulties finding individuals to maintain the site.",
    ];
    const assessText = [
        "This is underway! We are experimenting with different volunteers maintaining the site, and we are also carefully observing the onboarding of our new members. So far, the maintenance part seems to be running smoothly.", "I have moved to Maine, so I am no longer directly involved with the site, but it is still up, running, and being managed by CCL Volunteers."
    ];
    const refineText = [
        "For now, the website appears to be serving its purpose. However, as the political situation change and CCL's goals evolve, we will continue to revisit its success.",
    ];
    const posterText = ["I volunteered to design the promotional materials for a concert put on by CCL in Portland, ME. The desired mood was hopeful and uplifting and the goal was to bring people together, inspire climate action, and raise money for CCL.", "I painted the imagery with ink and watercolor. It was my first attempt with watercolor painting and took many iterations to get right. I then imported the imagery and completed the rest of the design in Photoshop."
    ]
    return <article className="center projectSection">
        <h3>Citizens Climate Lobby</h3>
        <p>Citizens’ Climate Lobby (CCL) is a non-partisan advocacy group focused on building public support and political will for climate legislation. I have been volunteering with CCL since 2019. I have done a lot of design and development work with CCL, including building a website for the Wisconsin branch of CCL, and desigining a poster for a concert to garner support for addressing climate change.</p>
        <h4>Concert for Climate</h4>
        <ImageSection className="painting" text={posterText} sourceImg={Poster} altText={"Concert for Climate Poster"}/>
        <ImageSection className="painting" text="" sourceImg={Banner} altText={"Concert for Climate Banner"}/>
        <p></p>
        <br />
        <h3>Wisconsin CCL Website</h3>
        <p>A website to share information and onboard new members to Wisconsin chapters of Citizens' Climate Lobby. Website designed to adhere to existing CCL branding and style.</p>
        <div><a href="https://blk.emu.mybluehost.me/" rel="noreferrer" target="_blank">View Live Site</a></div>
        <img src={CoverMain} alt="CCL Cover" />
        <Section number="1" className="center" value="the design itch" text={itchText} hasQuote={false}/>
        <Section number="2" className="center" value="research" text={researchText} hasQuote={false}/>
        <Section number="3" className="center" value="design focus" text={problemText} hasQuote={false}/>
        <Section number="4" className="center" value="prototype" text={prototypeText} hasQuote={false}/>
        <img src={SiteMap} alt="CCL Site Map" />
        <img src={HomePage} alt="CCL Wireframe" />
        <Section number="5" className="center" value="implement" text={implementText} hasQuote={false}/>
        <div><a href="https://blk.emu.mybluehost.me/" rel="noreferrer" target="_blank">View Live Site</a></div>
        <img src={CCLCover} alt="CCL Site" />
        <img src={UserGuide} alt="CCL User Guide" />
        <Section number="6" className="center" value="assess impact" text={assessText} hasQuote={false}/>
        <Section number="7" className="center" value="continuous refinement" text={refineText} hasQuote={false}/>
  </article>
}

export default CCL;