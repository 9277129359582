
import ProjectNavLink from "./projectNavLink";

const ProjectNav = ({itemList, urlList}) => {

  return <nav className="project-nav">
    <ul>
      {itemList.map((value, index) => {
        return <ProjectNavLink key={value} value={value} index={index} urlValue={urlList[index]}/>
      })}
    </ul>
  </nav>
}

export default ProjectNav;