import Section from "./section";
import PageTitle from "./pageTitle";
import Diamond from "./diamond";

const itchText = ["I call the catalyst of the design process an itch, because it implies something bothersome yet unknown. At the beginning, we know something is off or wrong or could be improved, but our understanding is usually very limited.", "In the beginning, we leave space for the unknown."];
const researchText = [
    "\"Much of the confusion we encounter in problem solving today results from misconceptions about the nature of change in social systems and their environments.\" - John Pourdehnad",
    "To what extent can and must we be experts in that which we design? Can we truly know a problem space if we are not recipients of its effects? How can we facilitate understanding of the systems that form a problem space?", "I consider design research the most important phase of design. However, design research gives the designer the power of knowing [something]. Does this strip the power to know away from those who are impacted by the design? If a designer is a facilitator, should they be the ones who know, and can they really know? Who holds the responsibility to know? We must be careful in the acquisition and possession of knowledge and think carefully how design research shifts this power. At the same time, however, no one can truly know how interventions will impact a system. Perhaps design research should be a compilation of knowledge from those who live within and adjacent to a problem space, and the role of the designer is to probe the intertwining of that knowledge with existing systems and be held accountable for the design's impacts.", "Overall, as faciliatators of a common shared understanding, designers must compile a diversity of viewpoints to foster understanding of all aspects of a problem space, understand systemic implications, and anticipate ripple effects."
    ];
const problemText = [
    "This is often called the problem statement, or design problem. I have moved away from describing it as a problem statement, because I am afraid that this framing scopes the design to focus too narrowly on solving a singular problem and relieves the designer of the responsibility to consider further reaching impacts of the design. I have drawn a lot of inspiration from Ron Wakkary, who warns of restricting the concerns of a design down to matters of a design problem, which he refers to as \"a thin and pure world that is more easily resolvable\". By restricting the area of design concern, we oversimplify the world in which we design, allowing us to more easily solve the problem at hand but resulting in a design that manifests very differently in a world of systemic complexity.",
    "Of course, there is an inherant contradiction here: to resolve anything we must restrict it enough that we can study it. The challenge is allowing for enough scoping to realistically handle the problem at hand while accounting for its existence in a larger and more complex world. Therefore, I have renamed this phase \"design focus\". This allows us to scope our area of concern to a manageable focus, while reminding the designer that there are still larger and more complex life worlds to account for.",
    "We must approach developing our design focus with the same level of care as we do a problem statement. Ultimately, we design for the issue we identify, so we must be certain that we are narrowing our focus to the right parts. This involves incorporating rigorous ethics and asking repeatedly: Should we be focused on this problem? What will happen if design for it? What are the considerations on different groups of people? Who are we designing for, and who else does it impact?"
];
const prototypeText = [
    "\"If, after weighing probabilities, and inventing safeguards, and straining for further information, I find myself unable to come to any fixed conclusion in reference to the future . . . the feeling of anxiety arises. When something happens for which I cannot account, I wonder.\" -Charles Sanders Peirce",
    "This sense of wonder, this need to explore and experiment, is a blessing. Design thinking gives us the freedom and creativity to wonder and to imagine. It allows us to be inspired by everything. It gives us the power to imagine a future that doesn't exist, and the courage to believe we can make it.",
    "But we must stress test our ideas. Because our designs live within webs of complex systems, because there are so many ways to know and so many sources of knowledge, and because we cannot anticipate all of the ways our creations impact the world, we must continuously test and refine. But we must do this in a way that is not harmful.", "This, I believe, is where computation and modeling can be useful. Testing our designs before we release them to society, especially for design that deals with social or political systems, is critical. I believe that using computational modeling to prototype how our designs may live within systems of complexity will enable us to develop much more robust designs and to ameliorate potential negative consequences of those designs. Digitally prototyping our ideas to avoid unleashing the unproven on society enables us to be much more intentional about how we influence our world."

];
const implementText = [
    "\"Solving our most complex problems will require augmenting design thinking with a systems thinking approach as the basis for action.\" -Rowan Conway",
    "Once we have identified a prototype we believe will thrive in practice, we implement. But the implementation is as critical as the design. Curating how the solution lives in the wild is just as important as the design of the solution itself, and much of this comes down to how it is implemented.", "Is training required? Education? Do people need to be made aware of the solution? Are there financial ramifications? How will the solution be maintained?"
];
const assessText = [
    "We must be intentional and honest in assessing how our solution exists in society. Designers generate system-level impacts, but we often fail to anticipate the ripple effects of our solutions. We therefore must be diligent in studying all aspects of our design.", "We must also be open to listening. Feedback and criticism can come from many places, and often folks most adversely impacted by a design are less able to have a voice advocating against it. Therefore, we must listen for feedback and critique from all directions.",
    "We should never be afraid to say that a solution is not working. We should never be afraid to go back to the drawing board and rework our designs."
];
const refineText = [
    "Let the cycle repeat.",
    "Design is nomadic, situated in circumstances, and circumstances are continuously changing. There is no such thing as a static solution- and we must always be evaluating."
];

const Philosophy = (props) => {
    return <section className="philosophySection bigSection center">
        <div className="diamondContainer">
        <Diamond className="one"/>
        <Diamond className="two"/>
        <Diamond className="three"/>
        </div>
        <PageTitle value="design philosophy" />
        <p className="italic philosophyType">My design philosophy is always evolving, and I take some space to share my reflections.</p>
        <Section number="1" className="center" value="the design itch" text={itchText} hasQuote={false}/>
        <Section number="2" className="center" value="research" text={researchText} hasQuote={true}/>
        <Section number="3" className="center" value="design focus" text={problemText} hasQuote={false}/>
        <Section number="4" className="center" value="prototype" text={prototypeText} hasQuote={true}/>
        <Section number="5" className="center" value="implement" text={implementText} hasQuote={true}/>
        <Section number="6" className="center" value="assess impact" text={assessText} hasQuote={false}/>
        <Section number="7" className="center" value="continuous refinement" text={refineText} hasQuote={false}/>
    </section>
}

export default Philosophy;