import PageTitle from "./pageTitle";
import Diamond from "./diamond";
import ProjectNav from "./projectNav";
import { Outlet } from "react-router-dom";

const Projects = (props) => {

    const projectNames = [
        "Climate Research",
        "Epistemology",
        "Smiles Per Hour",
        "AHFC",
        "CCL",
        "Paintings",
    ]

    const URLs = [
        "climateresearch",
        "epistemology",    
        "smiles",
        "ahfc",
        "ccl",
        "paintings",
    ]

    return <section className="bigSection center">
    <div className="diamondContainer">
    <Diamond className="one"/>
    <Diamond className="two"/>
    <Diamond className="three"/>
    </div>
    <PageTitle value="projects" />
    <ProjectNav itemList={projectNames} urlList={URLs}/>
    <Outlet />
</section>
}

export default Projects;