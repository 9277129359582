
import { Link, useLocation } from "react-router-dom";

const ProjectNavLink = ({value, index, urlValue}) => {
    const location = useLocation();
    let isActive = false;
    const pathArray = location.pathname.split("/");
    if((pathArray[pathArray.length - 1] === urlValue) || (pathArray[2] === undefined && urlValue === "myability") || (pathArray[2] === undefined && urlValue === "climateresearch")) {
        isActive = true;
    }
    return <Link to={urlValue} key={index} className={isActive ? "selected" : ""}><li>{value}</li></Link>
}

export default ProjectNavLink;